import { itemArray } from "./tradein-values.js";
import JSConfetti from "js-confetti";

function updateProductBarsEvent(data) {
  return new CustomEvent("updateProductBarsEvent", { detail: data });
}

const canvas = document.getElementById("confetti-canvas");
const button = document.getElementById("offer-box-actions-button-submit");

const jsConfetti = new JSConfetti({ canvas });

const dropdownOptions = {
  cpu: { 1: "Brand", 2: "Series", 3: "Model", 4: "Condition" },
  gpu: {
    1: "Manufacturer",
    2: "Series",
    3: "Model",
    4: "Brand",
    5: "Condition",
  },
  ram: {
    1: "Generation",
    2: "Application",
    3: "Capacity",
    4: "Kit",
    5: "Condition",
  },
  storage: { 1: "Storage Type", 2: "Capacity", 3: "Condition" },
  motherboard: {
    1: "Platform",
    2: "Chipset",
    3: "Form Factor",
    4: "Condition",
  },
};

const vendorLocks = [
  "Pro 5995WX",
  "Pro 5975WX",
  "Pro 5965WX",
  "Pro 5955WX",
  "Pro 5945WX",
];

const gpuManufacturers = ["Nvidia", "AMD", "Intel"];
const gpuSeries = {
  nvidia: [
    "GTX 700",
    "GTX 900",
    "GTX 1000",
    "RTX 2000",
    "RTX 3000",
    "RTX 4000",
    "Quadro",
    "Titan Series",
    "A Series",
    "Ada Series",
  ],
  amd: [
    "R7",
    "R9",
    "RX 400",
    "RX 500",
    "VEGA",
    "RX 5000",
    "RX 6000",
    "RX 7000",
  ],
  intel: ["Alchemist (A) Series"],
};
const gpuBrands = {
  Nvidia: [
    "Other",
    "Unknown",
    "Asus",
    "ASRock",
    "Dell",
    "EVGA",
    "Galax",
    "Gigabyte",
    "HP",
    "Lenovo",
    "MSI",
    "Nvidia",
    "PNY",
    "Zotac",
  ],
  AMD: [
    "Other",
    "Unknown",
    "AMD",
    "Asus",
    "ASRock",
    "Dell",
    "Gigabyte",
    "HP",
    "MSI",
    "PowerColor",
    "Sapphire",
    "VisionTek",
    "XFX",
  ],
  Intel: ["Other", "Unknown", "Intel", "Acer", "ASRock", "Gunnir"],
};

const cpuBrands = ["Intel", "AMD"];
const cpuSeries = {
  intel: ["Celeron", "Pentium", "Core i3", "Core i5", "Core i7", "Core i9"],
  amd: ["Ryzen 3", "Ryzen 5", "Ryzen 7", "Ryzen 9", "Threadripper"],
};

const motherboardPlatforms = ["Intel", "AMD"];
const motherboardChipsets = {
  intel: [
    "X99",
    "X299",
    "H110",
    "B150",
    "Q150",
    "H170",
    "Q170",
    "Z170",
    "B250",
    "Q250",
    "H270",
    "Q270",
    "Z270",
    "Z370",
    "H310",
    "B360",
    "B365",
    "H370",
    "Q370",
    "Z390",
    "H410",
    "B460",
    "H470",
    "Q470",
    "Z490",
    "H510",
    "B560",
    "H570",
    "Z590",
    "Z690",
    "Q670",
    "H670",
    "B660",
    "H610",
    "Z790",
    "H770",
    "B760",
  ],
  amd: [
    "A320",
    "B350",
    "X370",
    "B450",
    "X470",
    "A520",
    "B550",
    "X570",
    "A620",
    "B650",
    "B650E",
    "X670",
    "X670E",
    "X399",
    "TRX40",
    "TRX80",
    "WRX80",
    "TRX50",
    "WRX90",
  ],
};
const motherboardFormFactor = ["EATX", "ATX", "mATX", "ITX"];

const ramGeneration = ["DDR3", "DDR4", "DDR5"];
const ramType = ["Desktop DIMM", "Laptop SODIMM", "Server ECC"];
const ramCapacity = ["8 GB", "16 GB", "32 GB", "64 GB", "128 GB"];
const ramKits = {
  "8 GB": ["1x8 GB Stick", "2x4 GB Kit"],
  "16 GB": ["1x16 GB Stick", "2x8 GB Kit", "4x4 GB Kit"],
  "32 GB": ["1x32 GB Stick", "2x16 GB Kit", "4x8 GB Kit"],
  "64 GB": ["1x64 GB Stick", "2x32 GB Kit", "4x16 GB Kit", "8x8 GB Kit"],
  "128 GB": ["1x128 GB Stick", "2x64 GB Kit", "4x32 GB Kit", "8x16 GB Kit"],
};

const storageTypes = ["M.2 NVMe SSD", "SATA SSD", "HDD (Hard Drive)"];
const storageCapacities = {
  "M.2 NVMe SSD": [
    "Under 240 GB",
    "240 GB - 480 GB",
    "480 GB - 750 GB",
    "750 GB - 999 GB",
    "1 TB",
    "2 TB",
    "4 TB",
    "8 TB",
  ],
  "SATA SSD": [
    "Under 240 GB",
    "240 GB - 480 GB",
    "480 GB - 750 GB",
    "750 GB - 999 GB",
    "1 TB",
    "1.2 TB",
    "1.6 TB",
    "2 TB",
    "4 TB",
    "8 TB",
  ],
  "HDD (Hard Drive)": [
    "Under 240 GB",
    "240 GB - 480 GB",
    "480 GB - 750 GB",
    "750 GB - 999 GB",
    "1 TB",
    "2 TB",
    "3 TB",
    "4 TB",
    "8 TB",
    "10 TB",
    "12 TB",
    "14 TB",
    "16 TB",
    "18 TB",
    "20 TB",
  ],
};

var currentType;
var previousType;
var searchActive = false;
var tradeInProgress = false;

(function ($) {
  const populateFunctions = {
    gpu: populateGPUDropdowns,
    cpu: populateCPUDropdowns,
    ram: populateRamDropdowns,
    motherboard: populateMotherboardDropdowns,
    storage: populateStorageDropdowns,
  };

  $(".tradein-form-quantity-amount").on("keydown", function (event) {
    let key = event.key;
    let value = event.target.value;
    let new_value = Number(value + key);
    let max = Number(event.target.max);
    if (new_value > max || new_value < 1 || key === ".") {
      event.preventDefault();
    }

    if(key === "-") {
      event.preventDefault();
    }

    if(value === "0") {
      event.preventDefault();
      event.target.value = 1;
    }

  });

  function toggleRequiredStatements() {
    if ($("div[filter='condition']").attr("selection") == "Brand New Sealed") {
      $(".bnib-integrity-statement")
        .show()
        .find(".form-check-input")
        .attr("required", true);
    } else {
      $(".bnib-integrity-statement")
        .hide()
        .find(".form-check-input")
        .attr("required", false);
    }

    if (
      $.inArray($("div[filter='model']").attr("selection"), vendorLocks) !== -1
    ) {
      $(".tr-vendor-statement")
        .show()
        .find(".form-check-input")
        .attr("required", true);
    } else {
      $(".tr-vendor-statement")
        .hide()
        .find(".form-check-input")
        .attr("required", false);
    }

    $(".bnib-integrity-statement")
      .find(".form-check-input")
      .prop("checked", false);

    $(".tr-vendor-statement").find(".form-check-input").prop("checked", false);
  }

  function toggleOfferButton() {
    //enable add to offer button if all selections have been made.
    //Or disable on type switch

    if ($(".dropdown-clone").length < 1) {
      $(".tradein-form-post-item-button").addClass("disabled");
      return;
    }

    $(".dropdown-clone").each(function () {
      if ($(this).attr("selection") == "") {
        $(".tradein-form-post-item-button").addClass("disabled");

        toggleRequiredStatements();

        return;
      }

      toggleRequiredStatements();

      $(".tradein-form-post-item-button").removeClass("disabled");
    });
  }

  // --- SEARCH FUNCTION --- //

  function toggleSearchModal(e, override) {
    if (override == true) {
      toggleDropdown();
      $(".tradein-form-trade-wrapper-absolute-pseudo").removeClass("visible");
      $(".tradein-form-search-bar").val("").trigger("blur");
      searchActive = false;
      return;
    }

    let searchTarget = document.querySelector(".tradein-form-search-wrapper");
    if (searchActive && !$.contains(searchTarget, e.target)) {
      $(".tradein-form-search-bar").val("").trigger("blur");
      $(".tradein-form-search-results").removeClass(
        "tradein-form-search-results-focus"
      );
      $(".tradein-form-search-results-list").empty();

      $(".tradein-form-trade-wrapper-absolute-pseudo").fadeOut(
        300,
        0,
        function () {
          $(".tradein-form-trade-wrapper-absolute-pseudo").removeClass(
            "visible"
          );
        }
      );
      searchActive = false;
      return;
    } else if ($.contains(searchTarget, e.target) && !searchActive) {
      $(".tradein-form-search-bar").val("");
      $(".tradein-form-search-results").addClass(
        "tradein-form-search-results-focus"
      );
      $(".tradein-form-trade-wrapper-absolute-pseudo").addClass("visible");
      $(".tradein-form-trade-wrapper-absolute-pseudo").fadeTo(300, 0.4);
      $(".tradein-form-search-results-title").html(
        "Type to begin your search. Search tips:" +
          "<li>" +
          "For best results, exclude specific brand names such as 'Asus' or 'MSI'." +
          "<li>" +
          "You can filter by model, series, type, etc. using the syntax 'model: ' " +
          "<li>" +
          "Search for ram or storage using the format: '2x32 GB DDR4', '1 TB M.2' "
      );
      toggleDropdown(); // Toggle dropdowns off
      searchActive = true;
      return;
    }
    if (!searchActive) {
      //Edge case prevention
      $(".tradein-form-trade-wrapper-absolute-pseudo").removeClass("visible");
      $(".tradein-form-search-bar").val("").trigger("blur");
    }
  }

  function removeDuplicatesAndConditions(array) {
    var uniqueObjects = [];
    var names = [];

    for (var i = 0; i < array.length; i++) {
      var object = array[i];

      if (!names.includes(object.name)) {
        uniqueObjects.push(object);
        names.push(object.name);
      }
    }

    return uniqueObjects;
  }

  function filterSearch(searchTerm) {
    searchTerm = searchTerm.toLowerCase().trim();
    var searchTerms = searchTerm.split(/\s+/);
    var exclusionList = ["evga", "asus", "msi", "gigabyte"]; //ignore
    var filteredSearchTerms = searchTerms.filter(function (term) {
      return !exclusionList.includes(term);
    });

    var filteredData = searchItemArray.filter(function (item) {
      var itemValues = Object.values(item).map(function (value) {
        return typeof value === "string" ? value.toLowerCase() : value;
      });
      var matchCount = 0;
      filteredSearchTerms.forEach(function (term) {
        if (term.includes(":")) {
          var parts = term.split(":");
          var key = parts[0].trim();
          var val = parts[1].trim();
          var regex = new RegExp(val.replace(/\s/g, ".+?"), "i"); //TODO: fix \ ( ) compatibility error
          if (
            itemValues.some(function (value, index) {
              value = String(value).toLowerCase();
              var keyName = Object.keys(item)[index].toLowerCase();
              return keyName === key && regex.test(value);
            })
          ) {
            matchCount++;
          }
        } else {
          var regex = new RegExp(term.replace(/\s/g, ".+?"), "i"); //TODO: fix \ ( ) compatibility error
          if (
            itemValues.some(function (value) {
              value = String(value).toLowerCase();
              return regex.test(value);
            })
          ) {
            matchCount++;
          }
        }
      });
      return matchCount === filteredSearchTerms.length;
    });
    return filteredData;
  }

  function purgeDropdowns(category) {
    currentType != category ? $(".dropdown-clone").remove() : null;
  }

  function getDropdownTypes(type, step) {
    return type && step ? dropdownOptions[type][step] : dropdownOptions[type];
  }

  function setcurrentType(name) {
    if (
      typeof currentType === "undefined" &&
      typeof previousType === "undefined"
    ) {
      //First run if both undefined. Dont set previousType
      currentType = name.toLowerCase();
      return;
    }
    previousType = currentType.toLowerCase();
    currentType = name.toLowerCase();
  }

  function makeConditions() {
    $('div[filter="condition"]')
      .find(".tradein-dropdown-options")
      .append(
        $("<li/>").attr("id", "Used").html("Used"),
        $("<li/>").attr("id", "Used with Box").html("Used With Box"),
        $("<li/>").attr("id", "Brand New Sealed").html("Brand New Sealed")
      );
  }

  function generateDropdowns(category) {
    if (previousType == currentType) {
      return;
    }

    purgeDropdowns();

    for (let i = 0; i < Object.keys(getDropdownTypes(category)).length; i++) {
      let dropdownFilter = getDropdownTypes(category, i + 1).toLowerCase();
      let dropdownFilterName = getDropdownTypes(category, i + 1);
      $("#tradein-dropdown-template")
        .clone()
        .appendTo(".tradein-form-selectors-grid")
        .addClass("dropdown-clone")
        .attr({ filter: dropdownFilter, step: i + 1, selection: "" })
        .css("display", "flex")
        .removeAttr("id")
        .find(".tradein-dropdown-title-absolute")
        .text(dropdownFilterName);
    }

    //Append info icon to condition dropdown
    $('div[filter="condition"]')
      .find(".tradein-dropdown-title-absolute")
      .append(
        " <i class='fa fa-info-circle fa-1x' aria-hidden='true' data-bs-toggle='modal' data-bs-target='#exampleModal' style='cursor: pointer'></i>"
      );

    //Remove not in list from condition dropdown
    $('div[filter="condition"]').find("#not-in-list").remove();

    //Remove not in list from brand dropdown
    $('div[filter="brand"]').find("#not-in-list").remove();

    //Remove not in list from kit dropdown
    $('div[filter="kit"]').find("#not-in-list").remove();

    //Remove not in list from form factor dropdown
    $('div[filter="form factor"]').find("#not-in-list").remove();

    $(".dropdown-clone").not("[step=1]").addClass("tradein-dropdown-disabled");

    populateDropdowns();
    showQuantitySelector();
  }

  //Show quantity selector on all dropdowns
  function showQuantitySelector() {
    $(".tradein-quantity-selector")
      .css("display", "flex")
      .appendTo(".tradein-form-selectors-grid");
    $(".tradein-form-quantity-amount").val(1);
  }

  function populateDropdowns() {
    emptyDropdowns();

    const populateFunction = populateFunctions[currentType];

    if (populateFunction) {
      populateFunction();
    }

    makeConditions();
  }

  function populateCPUDropdowns() {
    let brandSelection = $("[step=1]").attr("selection");
    let seriesSelection = $("[step=2]").attr("selection");

    for (var i = 0; i < cpuBrands.length; i++) {
      $($("[step=1]").find(".tradein-dropdown-options")).append(
        $("<li/>").attr("id", cpuBrands[i]).html(cpuBrands[i])
      );
    }

    if (brandSelection != "") {
      let selection = brandSelection.toLowerCase();
      for (var i = 0; i < cpuSeries[selection].length; i++) {
        $($("[step=2]").find(".tradein-dropdown-options")).append(
          $("<li/>")
            .attr("id", cpuSeries[selection][i])
            .html(cpuSeries[selection][i])
        );
      }
    }

    if (seriesSelection != "") {
      let selection = seriesSelection.toLowerCase();
      for (var i = 0; i < searchItemArray.length; i++) {
        if (
          typeof searchItemArray[i].series != "undefined" &&
          searchItemArray[i].series.toLowerCase() == selection
        ) {
          $($("[step=3]").find(".tradein-dropdown-options")).append(
            $("<li/>")
              .attr("id", searchItemArray[i].model)
              .html(searchItemArray[i].model)
          );
          $('[data-bs-toggle="tooltip"]').tooltip();
        }
      }
    }
  }

  function populateGPUDropdowns() {
    let manufacturerSelection = $("[step=1]").attr("selection");
    let seriesSelection = $("[step=2]").attr("selection");
    let modelSelection = $("[step=3]").attr("selection");

    for (var i = 0; i < gpuManufacturers.length; i++) {
      $($("[step=1]").find(".tradein-dropdown-options")).append(
        $("<li/>").attr("id", gpuManufacturers[i]).html(gpuManufacturers[i])
      );
    }

    if (manufacturerSelection != "") {
      let selection = manufacturerSelection.toLowerCase();
      for (var i = 0; i < gpuSeries[selection].length; i++) {
        $($("[step=2]").find(".tradein-dropdown-options")).append(
          $("<li/>")
            .attr("id", gpuSeries[selection][i])
            .html(gpuSeries[selection][i])
        );
      }
    }

    if (seriesSelection != "") {
      let selection = seriesSelection.toLowerCase();
      for (var i = 0; i < searchItemArray.length; i++) {
        if (
          typeof searchItemArray[i].series != "undefined" &&
          searchItemArray[i].series.toLowerCase() == selection
        ) {
          $($("[step=3]").find(".tradein-dropdown-options")).append(
            $("<li/>")
              .attr("id", searchItemArray[i].model)
              .html(searchItemArray[i].model)
          );
        }
      }
    }

    if (modelSelection != "") {
      let targetBrands = gpuBrands[manufacturerSelection];
      if (targetBrands != null) {
        for (var i = 0; i < targetBrands.length; i++) {
          $($("[step=4]").find(".tradein-dropdown-options")).append(
            $("<li/>").attr("id", targetBrands[i]).html(targetBrands[i])
          );
        }
      }
    }
  }

  function populateRamDropdowns() {
    let capacitySelection = $("[step=3]").attr("selection");

    for (var i = 0; i < ramGeneration.length; i++) {
      $($("[step=1]").find(".tradein-dropdown-options")).append(
        $("<li/>").attr("id", ramGeneration[i]).html(ramGeneration[i])
      );
    }

    for (var i = 0; i < ramType.length; i++) {
      $($("[step=2]").find(".tradein-dropdown-options")).append(
        $("<li/>").attr("id", ramType[i]).html(ramType[i])
      );
    }

    for (var i = 0; i < ramCapacity.length; i++) {
      $($("[step=3]").find(".tradein-dropdown-options")).append(
        $("<li/>").attr("id", ramCapacity[i]).html(ramCapacity[i])
      );
    }

    if (capacitySelection != "") {
      for (var i = 0; i < ramKits[capacitySelection].length; i++) {
        $($("[step=4]").find(".tradein-dropdown-options")).append(
          $("<li/>")
            .attr("id", ramKits[capacitySelection][i])
            .html(ramKits[capacitySelection][i])
        );
      }
    }
  }

  function populateMotherboardDropdowns() {
    let platformSelection = $("[step=1]").attr("selection");
    let chipsetSelection = $("[step=2]").attr("selection");
    let formFactorSelection = $("[step=3]").attr("selection");

    for (var i = 0; i < motherboardPlatforms.length; i++) {
      $($("[step=1]").find(".tradein-dropdown-options")).append(
        $("<li/>")
          .attr("id", motherboardPlatforms[i])
          .html(motherboardPlatforms[i])
      );
    }

    if (platformSelection != "") {
      let selection = platformSelection.toLowerCase();
      for (var i = 0; i < motherboardChipsets[selection].length; i++) {
        $($("[step=2]").find(".tradein-dropdown-options")).append(
          $("<li/>")
            .attr("id", motherboardChipsets[selection][i])
            .html(motherboardChipsets[selection][i])
        );
      }
    }

    if (chipsetSelection != "") {
      let selection = chipsetSelection.toLowerCase();
      for (var i = 0; i < motherboardFormFactor.length; i++) {
        $($("[step=3]").find(".tradein-dropdown-options")).append(
          $("<li/>")
            .attr("id", motherboardFormFactor[i])
            .html(motherboardFormFactor[i])
        );
      }
    }
  }

  function populateStorageDropdowns() {
    let typeSelection = $("[step=1]").attr("selection");

    for (var i = 0; i < storageTypes.length; i++) {
      $($("[step=1]").find(".tradein-dropdown-options")).append(
        $("<li/>").attr("id", storageTypes[i]).html(storageTypes[i])
      );
    }

    //TODO: Remove steps denomination and find the dropdown options via filter; its not needed
    if (typeSelection != "") {
      for (var i = 0; i < storageCapacities[typeSelection].length; i++) {
        $($("[step=2]").find(".tradein-dropdown-options")).append(
          $("<li/>")
            .attr("id", storageCapacities[typeSelection][i])
            .html(storageCapacities[typeSelection][i])
        );
      }
    }
  }

  function emptyDropdowns() {
    $($(".dropdown-clone").find(".tradein-dropdown-options"))
      .children(":not(#not-in-list)")
      .remove(); //Remove all dropdown options from all dropdowns
  }

  function toggleDropdown(dropdown) {
    if (dropdown == null) {
      //Toggle off all dropdowns
      $(".tradein-dropdown-caret").removeClass("tradein-dropdown-caret-rotate");
      $(".tradein-dropdown-options").removeClass("opened");
      return;
    }

    const caret = $(dropdown).find(".tradein-dropdown-caret");

    caret.toggleClass("tradein-dropdown-caret-rotate");
    $(dropdown)
      .siblings()
      .each(function () {
        $(this)
          .find(".tradein-dropdown-caret")
          .removeClass("tradein-dropdown-caret-rotate");
        $(this).find(".tradein-dropdown-options").removeClass("opened");
      });

    dropdown.find(".tradein-dropdown-options").toggleClass("opened");
  }

  function autoFillDropdowns(data) {
    setcurrentType(data.type);
    generateDropdowns(currentType); // first generate new dropdowns for new type
    $(".dropdown-clone").each(function (index) {
      let dropdownFilter = $(this).attr("filter");
      if (dropdownFilter != "condition") {
        //Leave condition blank always regardless of tradein data; RAM search has condition embedded!
        for (let key in data) {
          if (dropdownFilter == key) {
            reconfigureDropdowns(this, data[key]);
          }
        }
      }
    });
    populateDropdowns();
    toggleOfferButton();
  }

  function reconfigureDropdowns(thisElementDropdown, selection) {
    var currentStep = parseFloat($(thisElementDropdown).attr("step"));
    $(thisElementDropdown).attr("selection", selection);

    //Reconfigure dropdowns
    $(".dropdown-clone").each(function (index) {
      //dropdown-clone as those are non template
      let currentDropdown = $(".dropdown-clone")[index];
      //close and clear the dropdowns that are outside selection scope
      if (parseFloat($(currentDropdown).attr("step")) > currentStep + 1) {
        $(currentDropdown)
          .addClass("tradein-dropdown-disabled")
          .attr("selection", "")
          .find(".tradein-dropdown-selected-title")
          .text("");
      }

      $(currentDropdown)
        .find(".tradein-dropdown-selected-title")
        .text($(currentDropdown).attr("selection"));
    });

    //reset currentStep + 1 as its selection has either changed or is invalid
    $(`[step=${currentStep + 1}]`)
      .removeClass("tradein-dropdown-disabled")
      .attr("selection", "")
      .find(".tradein-dropdown-selected-title")
      .text("");
  }

  function getTradeinItemFromData(data) {
    return itemArray
      .filter(function (item) {
        var matches = 0;
        var validKeys = Object.keys(item);
        for (var key in data) {
          if (data[key] !== "" && validKeys.includes(key)) {
            if (typeof item[key] === "string") {
              if (item[key].toLowerCase() === data[key].toLowerCase()) {
                matches++;
              }
            } else {
              if (item[key] == data[key]) {
                matches++;
              }
            }
          }
        }
        return matches > 0;
      })
      .sort(function (a, b) {
        //Sort data by most matching key-value pairs for most accurate result
        var aMatches = 0;
        var bMatches = 0;
        for (var key in data) {
          if (data[key] !== "") {
            if (typeof a[key] === "string" && typeof data[key] === "string") {
              if (a[key].toLowerCase() === data[key].toLowerCase()) {
                aMatches++;
              }
            } else {
              if (a[key] == data[key]) {
                aMatches++;
              }
            }
            if (typeof b[key] === "string" && typeof data[key] === "string") {
              if (b[key].toLowerCase() === data[key].toLowerCase()) {
                bMatches++;
              }
            } else {
              if (b[key] == data[key]) {
                bMatches++;
              }
            }
          }
        }
        return bMatches - aMatches;
      });
  }

  // --- ///

  var searchItemArray = removeDuplicatesAndConditions(itemArray); //Search only, no Ajax queries
  // --- UTILITY --- ///

  $(".tr-vendor-statement")
    .hide()
    .find(".form-check-input")
    .attr("required", false);
  $(".bnib-integrity-statement")
    .hide()
    .find(".form-check-input")
    .attr("required", false);

  $(document).mouseup(function (e) {
    toggleSearchModal(e);
  });

  // Update results based on user input
  $(document).on("input", ".tradein-form-search-bar", function () {
    let searchTerm = $(this).val();
    let resultsList = $(".tradein-form-search-results-list");
    resultsList.empty();

    if (searchTerm == "") {
      //Guard for blank search
      $(".tradein-form-search-results-title").html(
        "Type to begin your search. Search tips:" +
          "<li>" +
          "For best results, exclude specific brand names such as 'Asus' or 'MSI'." +
          "<li>" +
          "You can filter by model, series, type, etc. using the syntax 'model: ' " +
          "<li>" +
          "Search for ram or storage using the format: '2x32 GB DDR4', '1 TB M.2' "
      );
      return;
    }

    let filteredData = filterSearch(searchTerm);

    if (filteredData.length) {
      $(".tradein-form-search-results-title").text("");
      filteredData.forEach(function (item) {
        let option = $("<li>").text(item.name);
        let categoryBox = $("<div>").text(
          typeof item.type != "undefined" ? `Type: ${item.type}` : "Type: N/A"
        );
        $(option)
          .appendTo(resultsList)
          .attr("id", item.name)
          .attr("tabindex", 0)
          .addClass("border-bottom border-light");
        $(categoryBox)
          .appendTo(option)
          .addClass("type-label d-none d-md-block");
      });
    } else {
      $(".tradein-form-search-results-title").text("No results found!");
    }
  });

  $(document).on("click", ".tradein-form-search-results-list li", function () {
    //For now, just finding item in the tradein table via name key value pair.
    let selectedTradeinItemSearchName = this.id;
    let selectedTradeinItem;
    for (let item in searchItemArray) {
      if (
        typeof searchItemArray[item].name != "undefined" &&
        searchItemArray[item].name == selectedTradeinItemSearchName
      ) {
        selectedTradeinItem = searchItemArray[item];
      }
    }

    if (typeof selectedTradeinItem != "undefined") {
      searchActive = false; //End search; item has been found
      $(".tradein-form-search-results").removeClass(
        "tradein-form-search-results-focus"
      );
      $(".tradein-form-search-results-list").empty();
      $(".tradein-form-trade-wrapper-absolute-pseudo").fadeTo(
        300,
        0,
        function () {
          $(".tradein-form-trade-wrapper-absolute-pseudo").removeClass(
            "visible"
          );
          $(this).css("display", "none");
        }
      );
      $(".tradein-form-search-bar").val("").trigger("blur");
      //Fill in dropdowns based on search item
      autoFillDropdowns(selectedTradeinItem);
      toggleOfferButton();
      toggleSelectButton();
    } else {
      console.warn(
        "Could not find tradein item from search! Please try something else."
      );
    }
  });

  // --- DROPDOWN FUNCTIONS --- //

  $(document).on("click", ".tradein-dropdown-selection-window", function () {
    toggleDropdown($(this).parent());
  });

  $(document).on("submit", ".tradein-form-post-item-button", function (e) {
    e.stopImmediatePropagation();
    return false;
  });

  $(document).on("click", ".tradein-form-post-item-button", function (e) {
    e.stopImmediatePropagation();
    if (!$(".tradein-form-submit-data")[0].checkValidity()) {
      $(".tradein-form-submit-data")[0].reportValidity();
      return;
    }

    let dataPoints = {};
    let dropdowns = $(".dropdown-clone");

    $(dropdowns).each(function (index) {
      let thisDropdown = dropdowns[index];

      dataPoints[$(thisDropdown).attr("filter")] =
        $(thisDropdown).attr("selection");
    });

    let tradeItemSearched = getTradeinItemFromData(dataPoints);
    let likelyItem = tradeItemSearched[0];

    let id;
    //From now on all products use master_id
    if (likelyItem["master id"] != null) {
      id = likelyItem["master id"];
    } else {
      id = likelyItem["master_id"];
    }

    var ajaxData = {
      action: "woocommerce_ajax_add_to_cart",
      nonce: $("#df-ajax-nonce").val(),
      product_id: id,
      quantity: $(".tradein-form-quantity-amount").val(),
      variation_id: likelyItem["id"],
      type: likelyItem["type"],
    };

    if (likelyItem["type"] == "RAM") {
      ajaxData["kit"] = likelyItem["kit"];
    }

    if (likelyItem["type"] == "GPU") {
      if (dataPoints["brand"] != null) {
        ajaxData["brand"] = dataPoints["brand"];
      }
      if (dataPoints["series"] != null) {
        ajaxData["series"] = dataPoints["series"];
      }
    }

    if (likelyItem["type"] == "STORAGE") {
      ajaxData["capacity"] = likelyItem["capacity"];
    }

    if (tradeInProgress == true) {
      return; //Guard AJAX wait
    }

    $(this).find(".load-modal").addClass("visible");
    //$(this).addClass("disabled");
    tradeInProgress = true;

    $.ajax({
      type: "post",
      url: $("#df-ajax-url").val(),
      data: ajaxData,
      success: function (response) {
        if (response.error) {
          console.log("ERROR! " + response.error);
        } else {
          $(".cart-desc").replaceWith(response["fragments"]["a.cart-desc"]);
          $(".full-width-cart-toggle").replaceWith(
            response["fragments"]["button.cart-desc-offcanvas"]
          );
          document.dispatchEvent(
            updateProductBarsEvent(response["fragments"]["cart-content"])
          );
          jsConfetti.addConfetti({
            emojis: ["💰", "💲", "🤑", "♻️", "💰", "💻", "🎮"],
            confettiNumber: 20,
            emojiSize: 50,
          });
          if (window.screen.width < 1400) {
            $("#CartOffcanvas").offcanvas("show");
          }
        }

        tradeInProgress = false;
      },
      complete: function () {
        $(".tradein-form-post-item-button")
          .find(".load-modal")
          .removeClass("visible");
        //$(".tradein-form-post-item-button").removeClass("disabled");
        tradeInProgress = false;
      },
    });
    e.preventDefault(); //Stop reload
  });

  $(document).on("click", ".tradein-dropdown-options li", function () {
    var thisElementDropdown = $(this).parent().parent();
    toggleDropdown(thisElementDropdown);

    $('[data-bs-toggle="tooltip"]').tooltip("hide");

    if (thisElementDropdown.attr("selection") == this.id) {
      return;
    }

    reconfigureDropdowns(thisElementDropdown, this.id);
    populateDropdowns();
    toggleOfferButton();
  });

  $(document).on("click", ".tradein-select", function () {
    if (this.id == "otherbulk") {
      return;
    }

    setcurrentType(this.id);
    generateDropdowns(currentType);
    toggleOfferButton();
    toggleSelectButton();
  });

  function toggleSelectButton() {
    $(document).find(".tradein-select").removeClass("selected");
    $(`#${currentType}`).addClass("selected");
  }

  // --- START --- //

  $(document).ready(function () {
    $('[data-bs-toggle="tooltip"]').tooltip();

    // button.addEventListener("click", () => {
    //   jsConfetti.addConfetti();
    // });

    //Default CPU selected
    setcurrentType("gpu");
    generateDropdowns(currentType);
    $(document).find("#gpu").addClass("selected");

    $(document).on("close-all", function () {
      toggleSearchModal(null, true);
    });
  });
})(jQuery);
